@media (min-width: 1025px) {
    .footer-container {
        background-color: #2c3544;
        color: #fff;
        text-align: center;
        font-size: 12px;
        height: 100%;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .footer-container .footer-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #353e4d;
        height: 80px;
        padding: 0 30px;
    }
    .footer-container .footer-top > img {
        width: 201px ;
        height: 60px;
    }

    .footer-container .footer-top > span {
        color: #fff;
        font-size: 10px;
        align-self: end;
        margin-bottom: 10px;
    }
    .footer-container .footer-bottom{
        padding: 0 30px;
    }
    .footer-container .footer-bottom .footer-nav{
        width: 100%;
        margin: 0 auto;
    }
    .footer-container .footer-bottom .footer-nav >ul  {
        display: table;
        width: 100%;
        margin: 0 auto;
    }
    .footer-container .footer-bottom .footer-nav >ul > li {
        list-style: none;
        text-transform: uppercase;
        padding:30px;
        display: table-cell;
        float: none !important;
        width: auto !important;
    }
    .footer-container .footer-bottom .footer-nav >ul > li > a{
        text-decoration-line: none;
        color: #ccc;
        font-size: 16px;

    }
    .footer-container .footer-bottom .footer-nav >ul > li .sub-ul {
        padding:0;
        list-style: none;
        margin: 20px 0 0;
    }
    .footer-container .footer-bottom .footer-nav >ul > li .sub-ul > li {
        margin-top:10px
    }
    .footer-container .footer-bottom .footer-nav >ul > li .sub-ul > li  > a{
        text-decoration-line: none;
        color: #8791a2;
        font-size: 13px;
    }
    .footer-container .footer-bottom .footer-nav .sub-ul li:hover a {
        color: #Fff;
    }

    .footer-container .m-footer-bottom{
        padding: 0;
        display: none;
    }
}



@media (min-width: 768px) and (max-width: 1024px) {
    .footer-container {
        background-color: #2c3544;
        color: #fff;
        text-align: center;
        font-size: 12px;
        height: 200px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .footer-container .footer-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 0 30px;
    }
    .footer-container .footer-top > img {
        width: 167px ;
        height: 50px;
    }
    .footer-container .footer-top > span {
        display: none;
    }
    .footer-container .footer-bottom{
        padding: 0 30px;
        display: none;
    }
    .footer-container .m-footer-bottom{
        padding: 0 50px;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap:10px;

    }
    .footer-container .m-footer-bottom .first-row{
        gap:50px;
        display: flex;
        flex-direction: row;

    }
    .footer-container .m-footer-bottom .first-row >span,a{
        font-size: 15px;
        color: #ececec;
        text-decoration: none;
    }

}

@media (min-width: 0px) and (max-width: 767px) {
    .footer-container {
        background-color: #2c3544;
        color: #fff;
        /*text-align: center;*/
        font-size: 12px;
        height: 100%;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .footer-container .footer-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 10px;
    }
    .footer-container .footer-top > img {
        width: 117px ;
        height: 35px;
    }
    .footer-container .footer-top > span {
        display: none;
    }
    .footer-container .footer-bottom{
        padding: 0 30px;
        display: none;
    }
    .footer-container .m-footer-bottom{
        padding: 10px 10px;
        /*margin: 10px 0;*/
        display: flex;
        flex-direction: column;
        align-items: start;
        gap:10px;

    }
    .footer-container .m-footer-bottom .first-row{
        /*gap:5px;*/
        display: flex;
        flex-direction: column;

    }
    .footer-container .m-footer-bottom .first-row >span,a{
        font-size: 12px;
        color: #ececec;
        text-decoration: none;
    }
}
