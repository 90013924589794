
@media (min-width: 768px) and (max-width: 1024px) {
    .drawer-language {
        display: flex;
        align-items: center;
        justify-content: start;
        height: 30px;
        font-weight: 500;
        color: #252525;
        cursor: pointer;
        list-style: none;
        margin: 10px;
        transition: all 0.3s ease-in-out;
        align-self: center;
    }
    .drawer-language > button {
        border: none;
        background-color: transparent;
        font-weight: 500;
        color: #252525;
        text-transform: uppercase;
        cursor: pointer;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 12px;
        outline: none;
    }

    .drawer-language > span {
        position: absolute;
        right: 15px;
    }
    .drawer-language button + button:before {
        content: "|";
        margin: 0 10px 0 0;
        font-size: 12px;

    }
    .drawer-list {
        text-transform: uppercase;
    }
    .main-title{
        width: 100%;
        font-size: 18px;
        padding : 10px;
        text-transform: uppercase;
    }
    .main-title > span{
        font-size: 18px;
        padding : 10px;
        color:#4969ff;
    }
    .sub-title{
        font-size: 16px;
        padding : 20px;
        width: 100%;
        justify-content: start;
        text-decoration-line: none;
        text-transform: uppercase;
    }
    .sub-title > a{
        text-decoration-line: none;
        color: #727272;

    }
    .sub-title a::before {
        content: "- ";
        color: #727272; /* 이 부분은 ' >'의 색상을 정의합니다. 필요에 따라 수정하세요. */
    }
}


@media (min-width: 0px) and (max-width: 767px) {
    .drawer-language {
        display: flex;
        align-items: center;
        justify-content: start;
        height: 30px;
        font-weight: 500;
        color: #252525;
        cursor: pointer;
        list-style: none;
        margin: 10px;
        transition: all 0.3s ease-in-out;
        align-self: center;
    }
    .drawer-language > button {
        border: none;
        background-color: transparent;
        font-weight: 500;
        color: #252525;
        text-transform: uppercase;
        cursor: pointer;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 12px;
        outline: none;
    }

    .drawer-language > span {
        position: absolute;
        right: 15px;
    }
    .drawer-language button + button:before {
        content: "|";
        margin: 0 10px 0 0;
        font-size: 12px;

    }
    .drawer-list {
        text-transform: uppercase;
    }
    .main-title{
        width: 100%;
        font-size: 18px;
        padding : 10px;
        text-transform: uppercase;
    }
    .main-title > span{
        font-size: 18px;
        padding : 10px;
        color:#4969ff;
    }
    .sub-title{
        font-size: 16px;
        padding : 20px;
        width: 100%;
        justify-content: start;
        text-decoration-line: none;
        text-transform: uppercase;
    }
    .sub-title > a{
        text-decoration-line: none;
        color: #727272;

    }
    .sub-title a::before {
        content: "- ";
        color: #727272; /* 이 부분은 ' >'의 색상을 정의합니다. 필요에 따라 수정하세요. */
    }
}

