@media (min-width: 1025px) {
    .guide-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .guide-container .guide-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .guide-container .guide-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .guide-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        gap:10px;
    }
    .guide-content .__guide_section {
       width: 100%;
       height: 100%;
    }
    .guide-content .__guide_section .guide-card {
        width: 800px;
        height: 800px;
    }
    .guide-content .__guide_section .guide-card:nth-child(1) {
        background-image: url("./../../assets/images/guide1.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
    .guide-content .__guide_section .guide-card:nth-child(2) {
        background-image: url("./../../assets/images/guide2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
    .guide-content .__guide_section .guide-card:nth-child(3) {
        background-image: url("./../../assets/images/guide3.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }


}
@media (min-width: 768px) and (max-width: 1024px) {

    .guide-container .guide-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .guide-container .guide-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .guide-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        gap:10px;
    }
    .guide-content .__guide_section {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .guide-content .__guide_section .guide-card {
        width: 800px;
        height: 800px;
    }
    .guide-content .__guide_section .guide-card:nth-child(1) {
        background-image: url("./../../assets/images/guide1.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
    .guide-content .__guide_section .guide-card:nth-child(2) {
        background-image: url("./../../assets/images/guide2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
    .guide-content .__guide_section .guide-card:nth-child(3) {
        background-image: url("./../../assets/images/guide3.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }


}
@media (min-width: 0px) and (max-width: 767px) {
    .guide-container .guide-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .guide-container .guide-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .guide-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        gap:10px;
    }
    .guide-content .__guide_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .guide-content .__guide_section .guide-card {
        width:300px;
        height: 300px;
    }
    .guide-content .__guide_section .guide-card:nth-child(1) {
        background-image: url("./../../assets/images/guide1.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
    .guide-content .__guide_section .guide-card:nth-child(2) {
        background-image: url("./../../assets/images/guide2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
    .guide-content .__guide_section .guide-card:nth-child(3) {
        background-image: url("./../../assets/images/guide3.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }

}
