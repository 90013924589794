@media (min-width: 1025px) {
    .business-container {

    }

    .business-header-image {
        background-image: url("./../../assets/images/business.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 80%;
        height: 500px;
        opacity: 0.6;
        position: relative;
    }

    .business-header-image .business-header-image-navi {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        color: #252525;
        padding-left: 50px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ccc;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .business-container {

    }

    .business-header-image {
        background-image: url("./../../assets/images/business.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 50%;
        height: 400px;
        opacity: 0.6;
        position: relative;
    }

    .business-header-image .business-header-image-navi {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        color: #252525;
        padding-left: 20px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ccc;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .business-container {

    }

    .business-header-image {
        background-image: url("./../../assets/images/business.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        opacity: 0.6;
        position: relative;
    }

    .business-header-image .business-header-image-navi {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        color: #252525;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ccc;
    }
}
