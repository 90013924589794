@media (min-width: 1025px) {
    .banner-section {
        height: 820px;

    }

    .banner-section .banner-img {
        width: 100%;
        display: flex;
    }

    .banner-section .banner-img .splide-banner {
        background-size: cover;
        background-position: center;
        height: 820px;
        width: 60%;
        float: left;
    }

    .banner-text {
        display: grid;
        float: right;
        width: 40%;
        height: 820px;
        padding-left: 70px;
        align-self: center;
    }

    .banner-text .banner__content {
        display: block;
        align-self: center;
    }

    .banner-text .banner__content > strong > em {
        font-size: 64px;
        font-weight: 800;
        font-style: normal;
        margin: 0;
        top: 10px;
    }

    .banner-text .banner__content > strong {
        font-size: 50px;
        font-weight: 300;
        line-height: 1.12;
        display: block;
        margin-bottom: 40px;
        word-break: keep-all;
    }

    .banner-text .banner__content > p {
        font-size: 18px;
    }


    @keyframes floatAnimation {
        0% {
            transform: translateX(0px);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: translateX(30px);
            opacity: 0;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .banner-section {
        height: 600px;

    }

    .banner-section .banner-img {
        width: 100%;
        display: flex;
    }

    .banner-section .banner-img .splide-banner {
        background-size: cover;
        background-position: center;
        height: 600px;
        width: 60%;
        float: left;
    }

    .banner-text {
        display: grid;
        float: right;
        width: 40%;
        height: 600px;
        padding-left: 30px;
        align-self: center;
    }

    .banner-text .banner__content {
        display: block;
        align-self: center;
    }

    .banner-text .banner__content > strong > em {
        font-size: 40px;
        font-weight: 800;
        font-style: normal;
        margin: 0;
        top: 10px;
    }

    .banner-text .banner__content > strong {
        font-size: 35px;
        font-weight: 300;
        line-height: 1.12;
        display: block;
        margin-bottom: 40px;
        word-break: keep-all;
    }

    .banner-text .banner__content > p {
        font-size: 15px;
    }


    @keyframes floatAnimation {
        0% {
            transform: translateX(0px);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: translateX(30px);
            opacity: 0;
        }
    }

}
@media (min-width: 0px) and (max-width: 767px) {
    .banner-section {
        height: 520px;

    }

    .banner-section .banner-img {
        width: 100%;
        display: flex;
    }

    .banner-section .banner-img .splide-banner {
        background-size: cover;
        background-position: center;
        height: 400px;
        width: 100%;
        float: left;
    }

    .banner-text {
        display: grid;
        /*float: right;*/
        width: 100%;
        height: 120px;
        padding: 10px;
        align-self: center;
    }

    .banner-text .banner__content {
        display: block;
        align-self: center;
    }

    .banner-text .banner__content > strong > em {
        font-size: 22px;
        font-weight: 800;
        font-style: normal;
        margin: 0;
        top: 10px;

    }

    .banner-text .banner__content > strong {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.12;
        display: block;
        text-align: end;
        margin-bottom: 20px;
        word-break: keep-all;
    }

    .banner-text .banner__content > p {
        font-size: 12px;
        text-align: end;
        padding:0;
        margin:0
    }


    @keyframes floatAnimation {
        0% {
            transform: translateX(0px);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: translateX(30px);
            opacity: 0;
        }
    }

}
