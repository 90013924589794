@media (min-width: 1025px) {


    .notice-container .notice-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .notice-container .notice-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }
    .notice-container .notice-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .notice-container .notice-content .search-section{
        width: 100%;
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
    }
    .notice-container .notice-content .search-section > input, button, select{
        height: 40px;
        border: 1px solid #e0dede;
    }
    .notice-container .notice-content .search-section > select{
        margin-right: 10px;
        width: 100px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #e0dede;
    }
    .notice-container .notice-content .search-section > button{
        position: relative;
        right: 0;
        margin-left: -30px;
        padding: 0;
        border:0 none;
        background:none;
    }
    .notice-container .notice-content .search-section > input{
        font-size: 14px;
        width: 300px;
        padding: 5px 10px;
    }
    .notice-container .notice-content .table-section > table{
        width : 1200px;
        font-size: 14px;
        border-top: 3px solid #252525;
    }
    .notice-container .notice-content .table-section > table > thead{
        text-align: center;
    }
    .notice-container .notice-content .table-section > table > thead > tr> th{
        border-bottom:1px solid #e0dede;
        border-right:1px solid #e0dede;
        height:40px;
    }
    .notice-container .notice-content .table-section > table > thead > tr> th:nth-child(4){
        border-bottom:1px solid #e0dede;
        border-right: none;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(1){
        width: 80px;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(3){
        width: 100px;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(4){
        width: 170px;
    }
    .notice-container .notice-content .table-section > table > tbody{
        height: 300px;
        text-align: center;
    }
    .notice-container .notice-content .table-section > table > tbody > tr > td:nth-child(2){
        text-align: start;
    }

}
@media (min-width: 768px) and (max-width: 1024px) {



    .notice-container .notice-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .notice-container .notice-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }
    .notice-container .notice-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .notice-container .notice-content .search-section{
        width: 100%;
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
    }
    .notice-container .notice-content .search-section > input, button, select{
        height: 40px;
        border: 1px solid #e0dede;
    }
    .notice-container .notice-content .search-section > select{
        margin-right: 10px;
        width: 100px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #e0dede;
    }
    .notice-container .notice-content .search-section > button{
        position: relative;
        right: 0;
        margin-left: -30px;
        padding: 0;
        border:0 none;
        background:none;
    }
    .notice-container .notice-content .search-section > input{
        font-size: 14px;
        width: 300px;
        padding: 5px 10px;
    }
    .notice-container .notice-content .table-section > table{
        width : 750px;
        font-size: 14px;
        border-top: 3px solid #252525;
    }
    .notice-container .notice-content .table-section > table > thead{
        text-align: center;
    }
    .notice-container .notice-content .table-section > table > thead > tr> th{
        border-bottom:1px solid #e0dede;
        border-right:1px solid #e0dede;
        height:40px;
    }
    .notice-container .notice-content .table-section > table > thead > tr> th:nth-child(4){
        border-bottom:1px solid #e0dede;
        border-right: none;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(1){
        width: 80px;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(3){
        width: 100px;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(4){
        width: 170px;
    }
    .notice-container .notice-content .table-section > table > tbody{
        height: 600px;
        text-align: center;
    }
    .notice-container .notice-content .table-section > table > tbody > tr > td:nth-child(2){
        text-align: start;
    }




}
@media (min-width: 0px) and (max-width: 767px) {
    .notice-container .notice-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .notice-container .notice-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }
    .notice-container .notice-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .notice-container .notice-content .search-section{
        width: 100%;
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
    }
    .notice-container .notice-content .search-section > input, button, select{
        height: 40px;
        border: 1px solid #e0dede;
    }
    .notice-container .notice-content .search-section > select{
        margin-right: 10px;
        width: 100px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #e0dede;
    }
    .notice-container .notice-content .search-section > button{
        position: relative;
        right: 0;
        margin-left: -30px;
        padding: 0;
        border:0 none;
        background:none;
    }
    .notice-container .notice-content .search-section > input{
        font-size: 14px;
        width: 200px;
        padding: 5px 10px;
    }
    .notice-container .notice-content .table-section > table{
        width : 350px;
        font-size: 14px;
        border-top: 3px solid #252525;
    }
    .notice-container .notice-content .table-section > table > thead{
        text-align: center;
    }
    .notice-container .notice-content .table-section > table > thead > tr> th{
        border-bottom:1px solid #e0dede;
        border-right:1px solid #e0dede;
        height:40px;
    }
    .notice-container .notice-content .table-section > table > thead > tr> th:nth-child(4){
        border-bottom:1px solid #e0dede;
        border-right: none;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(1){
        width: 60px;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(3){
        display: none;
    }
    .notice-container .notice-content .table-section > table > thead > tr > th:nth-child(4){
        width: 110px;
    }
    .notice-container .notice-content .table-section > table > tbody{
        height: 600px;
        text-align: center;
    }
    .notice-container .notice-content .table-section > table > tbody > tr > td:nth-child(2){
        text-align: start;
    }


}
