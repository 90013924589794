
@media (min-width: 1025px) {
    .smartcheck-container{
        width: 100%;
        height: 100%;
    }
    .smartcheck-container .smartcheck-title{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .smartcheck-container .smartcheck-title > h1 {
        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .smartcheck-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 10px;
    }
    .smartcheck-content .smartcheck-img {
        background-image: url("./../../assets/images/smartcheck.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        align-self: center;
        width: 1200px;
        height: 300px;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }
    .smartcheck-content .smartcheck-img > h1 {
        position: absolute;
        left: -20px;
        color: #163fff;
        font-size: 28px;
    }
    .smartcheck-content .smartcheck-list {
        display: flex;
        flex-direction: column;
        justify-content: start;

        margin-bottom: 50px;
        padding: 0 300px;
    }
    .smartcheck-content .smartcheck-list .smartcheck-list-title > h1 {
        padding-bottom:  8px;
        border-bottom: 2px solid #e8e8e8;
        font-size: 36px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .smartcheck-container{
        width: 100%;
        height: 100%;
    }
    .smartcheck-container .smartcheck-title{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .smartcheck-container .smartcheck-title > h1 {
        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .smartcheck-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 10px;
    }
    .smartcheck-content .smartcheck-img {
        background-image: url("./../../assets/images/smartcheck.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        align-self: center;
        width:100%;

        height: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }
    .smartcheck-content .smartcheck-img > h1 {
        position: absolute;
        bottom:-20px;
        left: 50%;
        transform: translateX(-50%);
        color: #163fff;
        font-size: 22px;
    }
    .smartcheck-content .smartcheck-list {
        display: flex;
        flex-direction: column;
        justify-content: start;

        margin-bottom: 50px;
        padding: 0 80px;
    }
    .smartcheck-content .smartcheck-list .smartcheck-list-title > h1 {
        padding-bottom:  8px;
        border-bottom: 2px solid #e8e8e8;
        font-size: 36px;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .smartcheck-container{
        width: 100%;
        height: 100%;
    }
    .smartcheck-container .smartcheck-title{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .smartcheck-container .smartcheck-title > h1 {
        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .smartcheck-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 10px;
    }
    .smartcheck-content .smartcheck-img {
        background-image: url("./../../assets/images/smartcheck.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        align-self: center;
        width: 100%;
        height: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }
    .smartcheck-content .smartcheck-img > h1 {
        display: none;
        position: absolute;
        top: -20px;

        color: #163fff;
        font-size: 14px;
    }
    .smartcheck-content .smartcheck-list {
        display: flex;
        flex-direction: column;
        justify-content: start;

        margin-bottom: 50px;
        padding: 0 10px;
    }
    .smartcheck-content .smartcheck-list .smartcheck-list-title > h1 {
        padding-bottom:  8px;
        border-bottom: 2px solid #e8e8e8;
        font-size: 30px;
    }
}

