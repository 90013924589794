@media (min-width: 1025px)  {
    .line-banner{
        background-color: #4969ff;
        color:#fff;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        padding: 30px 15px;
    }
    .line-banner .line-banner__title{
        font-weight: 800;
        font-size: 55px;
        line-height: 1;
        margin-bottom: 40px;
    }
    .line-banner .line-banner__description{
        font-weight: 500;
        font-size: 18px;
        margin:0;
    }

}
@media (min-width: 768px) and (max-width: 1024px) {
    .line-banner{
        background-color: #4969ff;
        color:#fff;
        display: grid;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 20px 15px;
    }
    .line-banner .line-banner__title{
        font-weight: 800;
        font-size: 24px;

        line-height: 1;
        margin-bottom: 25px;
    }
    .line-banner .line-banner__description{
        font-weight: 500;
        font-size: 14px;
        margin: 0;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .line-banner{
        background-color: #4969ff;
        color:#fff;
        height: 100%;
        padding: 20px 15px;
        display: grid;
        justify-content: center;
        align-items: center;

    }
    .line-banner .line-banner__title{
        font-weight: 800;
        font-size: 22px;

        line-height: 1;
        margin-bottom: 20px;
    }
    .line-banner .line-banner__description{
        font-weight: 500;
        font-size: 12px;
        margin: 0;
    }
}

