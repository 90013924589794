@media (min-width: 1025px) {
    .certification-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .certification-container .certification-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .certification-container .certification-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .certification-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 1600px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .__authentication_section{
        display: flex;
        flex-direction: column;
        padding: 30px 200px;
        width:100%
    }
    .__authentication_section > h1{
        text-transform: uppercase;
       font-size: 30px;
    }
    .__authentication_section .__authentication_section_detail{
        border-top: 2px solid #ccc;
        width:100%;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;

    }
    .__authentication_section .__authentication_section_detail .auth-card{
        width: 140px;
        height: 140px;
        margin: 0 20px 30px;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(1){
        background-image: url("./../../assets/images/koita.jpg");
        background-size: contain;
        background-position: center;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(2){
        background-image: url("./../../assets/images/kosa.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(3){
        background-image: url("./../../assets/images/copyright.gif");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(4){
        background-image: url("./../../assets/images/gs.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(5){
        background-image: url("./../../assets/images/jodalchung.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(6){
        background-image: url("./../../assets/images/kc.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(7){
        background-image: url("./../../assets/images/iso.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(8){
        background-image: url("./../../assets/images/patent.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .__certificate_section{
        display: flex;
        flex-direction: column;
        padding: 30px 200px;
        width:100%
    }
    .__certificate_section > h1{
        text-transform: uppercase;
        font-size: 30px;
    }
    .__certificate_section .__certificate_section_detail{
        border-top: 2px solid #ccc;
        width:100%;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;

    }
    .__certificate_section .__certificate_section_detail .certificate-card{
        width: 160px;
        height: 200px;
        margin: 0 20px 20px;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(1){
        background-image: url("./../../assets/certification/kc.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(2){
        background-image: url("./../../assets/certification/best_ko.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(3){
        background-image: url("./../../assets/certification/lab.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(4){
        background-image: url("./../../assets/certification/efos_gs.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(5){
        background-image: url("./../../assets/certification/patent.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }



}
@media (min-width: 768px) and (max-width: 1024px) {

    .certification-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .certification-container .certification-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .certification-container .certification-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .certification-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .__authentication_section{
        display: flex;
        flex-direction: column;
        padding: 30px 70px;
        width:100%
    }
    .__authentication_section > h1{
        text-transform: uppercase;
        font-size: 30px;
    }
    .__authentication_section .__authentication_section_detail{
        border-top: 2px solid #ccc;
        width:100%;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;

    }
    .__authentication_section .__authentication_section_detail .auth-card{
        width: 100px;
        height: 100px;
        margin: 0 15px 30px;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(1){
        background-image: url("./../../assets/images/koita.jpg");
        background-size: contain;
        background-position: center;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(2){
        background-image: url("./../../assets/images/kosa.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(3){
        background-image: url("./../../assets/images/copyright.gif");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(4){
        background-image: url("./../../assets/images/gs.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(5){
        background-image: url("./../../assets/images/jodalchung.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(6){
        background-image: url("./../../assets/images/kc.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(7){
        background-image: url("./../../assets/images/iso.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(8){
        background-image: url("./../../assets/images/patent.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .__certificate_section{
        display: flex;
        flex-direction: column;
        padding: 30px 70px;
        width:100%
    }
    .__certificate_section > h1{
        text-transform: uppercase;
        font-size: 30px;
    }
    .__certificate_section .__certificate_section_detail{
        border-top: 2px solid #ccc;
        width:100%;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;

    }
    .__certificate_section .__certificate_section_detail .certificate-card{
        width: 120px;
        height:140px;
        margin: 0 20px 20px;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(1){
        background-image: url("./../../assets/certification/kc.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(2){
        background-image: url("./../../assets/certification/best_ko.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(3){
        background-image: url("./../../assets/certification/lab.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(4){
        background-image: url("./../../assets/certification/efos_gs.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(5){
        background-image: url("./../../assets/certification/patent.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }



}
@media (min-width: 0px) and (max-width: 767px) {
    .certification-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .certification-container .certification-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .certification-container .certification-title > h1 {
        text-transform: uppercase;
        font-size: 20px;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .certification-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .__authentication_section{
        display: flex;
        flex-direction: column;
        padding: 30px 30px;
        width:100%
    }
    .__authentication_section > h1{
        text-transform: uppercase;
        font-size: 18px;
    }
    .__authentication_section .__authentication_section_detail{
        border-top: 2px solid #ccc;
        width:100%;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;

    }
    .__authentication_section .__authentication_section_detail .auth-card{
        width: 70px;
        height: 70px;
        margin: 0 20px 20px;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(1){
        background-image: url("./../../assets/images/koita.jpg");
        background-size: contain;
        background-position: center;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(2){
        background-image: url("./../../assets/images/kosa.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(3){
        background-image: url("./../../assets/images/copyright.gif");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(4){
        background-image: url("./../../assets/images/gs.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(5){
        background-image: url("./../../assets/images/jodalchung.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(6){
        background-image: url("./../../assets/images/kc.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(7){
        background-image: url("./../../assets/images/iso.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__authentication_section .__authentication_section_detail .auth-card:nth-child(8){
        background-image: url("./../../assets/images/patent.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .__certificate_section{
        display: flex;
        flex-direction: column;
        padding: 30px 30px;
        width:100%
    }
    .__certificate_section > h1{
        text-transform: uppercase;
        font-size: 18px;
    }
    .__certificate_section .__certificate_section_detail{
        border-top: 2px solid #ccc;
        width:100%;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;

    }
    .__certificate_section .__certificate_section_detail .certificate-card{
        width: 70px;
        height:100px;
        margin: 0 20px 20px;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(1){
        background-image: url("./../../assets/certification/kc.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(2){
        background-image: url("./../../assets/certification/best_ko.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(3){
        background-image: url("./../../assets/certification/lab.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(4){
        background-image: url("./../../assets/certification/efos_gs.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .__certificate_section .__certificate_section_detail .certificate-card:nth-child(5){
        background-image: url("./../../assets/certification/patent.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }


}
