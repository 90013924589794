/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-weight: 500;*/
/*}*/
/*body.korean-font {*/
/*  font-family: 'Noto Sans KR', sans-serif;*/
/*}*/

/*body.english-font {*/
/*  font-family: 'Oswald', sans-serif;*/
/*}*/


body {
  margin: 0;
  padding: 0;
  font-weight: 500;
}
body::-webkit-scrollbar {
   display: none;
 }


html[lang="ko"] body {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
}

html[lang="en"] body {
  font-family: 'Archivo', sans-serif;
  font-size: 17px;
}
