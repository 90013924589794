@media (min-width: 1025px) {
    .header-container {
        height: 130px;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #cccfd1;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
        justify-content: space-between;
        padding: 0 100px;
    }

    .header-container.active {
        /*height: 60px;*/
        top:-70px;
        transition: all 0.3s ease-in-out;
    }
    .header-container .logo {
        float: left;
        align-self: center;
    }
    .header-container.active .logo {
        position: relative;
        top:38px;
        transition: height 0.3s ease-in-out;
    }
    .header-container.active .logo > a {
        font-size: 0;
        width:150px;
        display: block;
        background-image: url("./../../assets/images/yit.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
    .header-container .logo > a {
        font-size: 0;
        width: 260px;
        height: 77.8px;
        display: block;
        background-image: url("./../../assets/images/yit.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .header-container .header-nav {
        float: right;
        padding-top: 10px;
        display: grid;
        right:0;
        position: relative;
    }
    .header-container .all-menu-btn {
        display: none;
        padding-top: 10px;
        width: 30px;

    }
    .header-container .language {
        position: absolute;
        right: 0;
        display: flex;
        align-items: start;
        margin: 8px;
        justify-content: end;
        height: 100%;
        font-weight: 500;
        color: #252525;
        cursor: pointer;
        list-style: none;
        padding-left: 0;
        transition: all 0.3s ease-in-out;
    }
    .header-container.active .language {
        position: absolute;
        right: 0;
        display: flex;
        align-items: start;
        margin: 8px;
        justify-content: end;
        height: 30px;
        top: 65px;
        font-weight: 500;
        color: #252525;
        cursor: pointer;
        list-style: none;
        padding-left: 0;
        transition: all 0.3s ease-in-out;
    }

    .header-container .language button + button:before {
        content: "|";
        margin: 0 10px 0 0;
        font-size: 11px;
    }

    .header-container .language > button {
        border: none;
        background-color: transparent;
        font-weight: 500;
        color: #252525;
        text-transform: uppercase;
        cursor: pointer;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 12px;
        outline: none;
    }

    .header-container .header-nav .menu {
        display: flex;
        align-items: end;
        padding-bottom:15px;
        justify-content: center;
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #252525;
        margin: 0;
        cursor: pointer;
        list-style: none;
        padding-left: 0;
        text-align: center;
        transition: all 0.3s ease-in-out;
    }
    .header-container .header-nav:hover .sub-menu-container{
        display: block;
        animation: slideDown 0.5s ease-out forwards;
    }
    .sub-menu-container-items{
        display: flex;
        width: 100%;
        gap:2px;
        padding-left:1px;
        padding-top: 10px;
    }
    .header-nav .menu > li {
        /*margin-right: 20px;*/
        /*margin-left: 50px;*/
        list-style: none;
        font-size: 16px;
        font-weight: 500;
        color: #252525;
        width: 150px;
        text-transform: uppercase;
    }
    .header-container .header-nav .menu > li > a {
        text-decoration-line: none;
        color: #252525;
    }
    .sub-menu-container {
        display: none;
        position: absolute;
        top: 101%;
        z-index: 90;
        transition: all 0.3s ease-out;
        /*border-top: 1px solid #cccfd1;*/
        left: 0;
        background-color: #fff;
        width:100%;
        height: 100%;
        text-align: center;
    }
    .sub-menu-container .sub-menu {
        list-style: none;
        padding: 0;
    }
    .sub-menu-container .sub-menu > li {
        margin-bottom: 16px;
        width: 150px;
    }
    .sub-menu-container .sub-menu > li > a {
        text-decoration: none;
        color: #656565;
        text-transform: uppercase;

    }
    .sub-menu-container .sub-menu > li:hover > a {
        border-bottom: 2.5px solid #6bb8ef; /* 밑줄처럼 보이게 하고 색상 지정 */
        padding-bottom: 1px; /* 밑줄과 텍스트 사이의 거리 조정 */
    }

    @keyframes slideDown {
        from {
            opacity: 0; /* 시작할 때 투명도 */
            transform: translateY(-20px); /* Y축으로 조금 위에 위치하게 시작 */
        }
        to {
            opacity: 1; /* 끝날 때 완전히 불투명 */
            transform: translateY(0); /* 최종 위치는 원래 위치 */
        }
    }

}
@media (min-width: 768px) and (max-width: 1024px){
    .header-container {
        height: 90px;
        display: flex;
        padding : 0 20px;
        width: 100%;
        border-bottom: 1px solid #cccfd1;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
        justify-content: space-between;
    }
    .header-container .logo {
        float: left;
        align-self: center;
    }

    .header-container .logo > a {
        font-size: 0;
        width: 200px;
        height: 59.85px;
        display: block;
        background-image: url("./../../assets/images/yit.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .header-container .header-nav {
        float: right;
        padding-top: 10px;
        display: none;
        right:0;
        position: relative;
    }
    .header-container .all-menu-btn {
        align-self: center;
        padding-top: 10px;
        width: 30px;
        padding-right: 40px;
        z-index: 80;

    }
    .header-container .all-menu-btn > svg {
        font-size: 42px;
    }
    .language {
        display: none;
        position: absolute;
        right: 0;
        top:0;
        margin: 4px;
        z-index: 75;
        justify-content: end;
        height: 100%;
        font-weight: 500;
        color: #252525;
        cursor: pointer;
        list-style: none;
        padding-left: 0;
        transition: all 0.3s ease-in-out;
    }

    .header-container .language button + button:before {
        content: "|";
        margin: 0 10px 0 0;
        font-size: 11px;
    }

    .header-container  .language > button {
        border: none;
        background-color: transparent;
        font-weight: 500;
        color: #252525;
        text-transform: uppercase;
        cursor: pointer;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 12px;
        outline: none;
    }

    .header-container .header-nav .menu {
        display: flex;
        align-items: end;
        padding-bottom: 10px;
        justify-content: start;
        height: 100%;
        width:100%;
        font-size: 13px;
        font-weight: 500;
        color: #252525;
        cursor: pointer;
        list-style: none;
        padding-left: 0;
        text-align: center;
        transition: all 0.3s ease-in-out;
    }
    .header-container .header-nav:hover .sub-menu-container{
        display: block;
        animation: slideDown 0.5s ease-out forwards;

    }
    .sub-menu-container-items{
        display: flex;
        width: 100%;
        padding-top: 10px;
        justify-content: space-between;
    }
    .header-nav .menu > li {
        /*margin-right: 20px;*/
        /*margin-left: 50px;*/
        list-style: none;
        font-size: 16px;
        font-weight: 500;
        color: #252525;
        width: 120px;
        text-transform: uppercase;
    }
    .header-container .header-nav .menu > li > a {
        text-decoration-line: none;
        color: #252525;
    }
    .sub-menu-container {
        display: none;
        position: absolute;
        top: 101%;
        z-index: 90;
        left: 0;
        background-color: #fff;
        width:100%;
        height:120px;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .sub-menu-container .sub-menu {
        list-style: none;
        padding: 0;
    }
    .sub-menu-container .sub-menu > li {
        margin-bottom: 16px;
        width: 100%;;
    }
    .sub-menu-container .sub-menu > li > a {
        text-decoration: none;
        color: #656565;
        text-transform: uppercase;

    }
    .sub-menu-container .sub-menu > li:hover > a {
        border-bottom: 2.5px solid #6bb8ef; /* 밑줄처럼 보이게 하고 색상 지정 */
        padding-bottom: 1px; /* 밑줄과 텍스트 사이의 거리 조정 */
    }

    @keyframes slideDown {
        from {
            opacity: 0; /* 시작할 때 투명도 */
            transform: translateY(-20px); /* Y축으로 조금 위에 위치하게 시작 */
        }
        to {
            opacity: 1; /* 끝날 때 완전히 불투명 */
            transform: translateY(0); /* 최종 위치는 원래 위치 */
        }
    }
}

/* 모바일 스타일 */
@media (min-width: 0px) and (max-width: 767px) {
    .header-container {
        height: 70px;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #cccfd1;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
        padding: 0 20px;
        justify-content: space-between;
    }
    .header-container .logo {
        float: left;
        align-self: center;
    }
    .header-container .logo > a {
        font-size: 0;
        width:120px;
        height: 35.9px;
        display: block;
        background-image: url("./../../assets/images/yit.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .header-container .header-nav {
        float: right;
        padding-top: 10px;
        display: none;

    }
    .header-container .all-menu-btn {
        align-self: center;
        width: 30px;
        background-color: #fff;
        border:0

    }
    .header-container .all-menu-btn:active {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 30px;
        background-color: #ccc;
        border-radius: 50%;
        border:0

    }
    .header-container .language{
        display: none;

    }
    .header-container .header-nav .menu{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #252525;
        margin:0;
        cursor: pointer;
        list-style:none;
        padding-left: 0;
        transition: all 0.3s ease-in-out;
    }
    .header-container .header-nav .menu > li{
        margin-left: 50px;
        /*position:relative;*/
    }
    .header-container .header-nav .menu > li{
        font-size: 16px;
        font-weight: 500;
        color: #252525;
        text-transform: uppercase;
    }
    .header-container .header-nav .menu > li >a{
        text-decoration-line: none;
        color: #252525;

    }
}
