@media (min-width: 1025px) {
    .scroll-top-section{
        position:fixed;
        bottom:50px;
        right:50px;
        z-index: 1000;
        display:block;
        width:50px;
        height:50px;
        background:url("./../../assets/images/top.png") no-repeat center center;
        background-size:cover;
        cursor:pointer;
        transition:all 0.3s ease;
        opacity: 0.7;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .scroll-top-section{
        position:fixed;
        bottom:20px;
        right:20px;
        z-index: 1000;
        display:block;
        width:50px;
        height:50px;
        background:url("./../../assets/images/top.png") no-repeat center center;
        background-size:cover;
        cursor:pointer;
        transition:all 0.3s ease;
        opacity: 0.7;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .scroll-top-section{
        position:fixed;
        bottom:20px;
        right:20px;
        z-index: 1000;
        display:block;
        width:40px;
        height:40px;
        background:url("./../../assets/images/top.png") no-repeat center center;
        background-size:cover;
        cursor:pointer;
        transition:all 0.3s ease;
        opacity: 0.7;
    }
}
