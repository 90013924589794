@media (min-width: 1025px) {
    .news-container {
        height: 100%;
        padding-top: 30px;
        width:100%;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    .news-container .title {
        display: flex;
        justify-content: center;
    }

    .news-container .title > h1 {
        font-weight: 600;
        border-bottom: 4px solid #4969ff;
        padding-bottom: 4px;
    }
    #news-prev, #news-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        background-color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;

    }
    #news-prev, #news-next .btn-icon {
        font-size: 40px;

    }
    #news-prev  {
        left: -120px;
    }
    #news-next {
        right: -120px;
    }

    #news-prev   .btn-icon {
        font-size: 60px;
        color: #4969ff;

    }
    #news-prev   .btn-icon:active {
        font-size: 60px;
        color: #252525;

    }
    #news-prev:disabled .btn-icon {
        font-size: 60px;
        color: #ccc;

    }

    #news-next  .btn-icon {
        font-size: 60px;
        color: #4969ff;
    }
    #news-next   .btn-icon:active {
        font-size: 60px;
        color: #252525;

    }
    #news-next:disabled .btn-icon {
        font-size: 60px;
        color: #ccc;

    }

    .news-content {
        margin-top: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        width: 100%;
        justify-content: center;
        position: relative;
    }
    .news-content .arrow {
        margin-top: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        width: 100%; /* 변경됨 */
        justify-content: center;
        position: relative;
    }


    .news-content .content-title {
        width:100%;
        height: 100%;
        display: flex;
        border-bottom: 1px solid #ccc;
    }
    .news-content .content-title > h1 {
        font-size: 22px;
        color: #6c6c6c;
        margin-left: 15px;
    }
    .news-content .content-card-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        padding: 20px;
        gap: 30px;
        scroll-behavior: smooth;
    }
    .news-content .content-card-container::-webkit-scrollbar {
        display: none;
    }
    .news-content .content-card-container .content-card {
        min-width: 380px;
        width:380px;
        height: 500px;
        border: 1px solid #e8e8e8;
        border-radius: 8px;

    }
    .news-content .content-card-container .content-card .content-card-header{
        min-width: 80px;
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;

    }
    .news-content .content-card-container .content-card .content-card-header .content-card-header-img{
       width:60px;
         height:60px;

    }
    .news-content .content-card-container .content-card .content-card-header .header-section{
        display: flex;
        flex-direction: column;
        text-align: end;
    }
    .news-content .content-card-container .content-card .content-card-header .header-section > h5{
        font-size: 20px;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    .news-content .content-card-container .content-card .content-card-header .header-section > span{
        font-size: 14px;
        font-weight: 300;
    }
    .news-content .content-card-container .content-card .content-card-main{
        width: 100%;
        height:420px;
        max-height: 420px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        position: relative;
    }

    .news-content .content-card-container .content-card .content-card-main .scroll-indicator{
        transition: all 0.5s ease-in-out;
        display: none;
    }
    .news-content .content-card-container .content-card .content-card-main::-webkit-scrollbar{
        display: none;
    }
    .news-content .content-card-container .content-card .content-card-main .content-card-main-img{
        width:200px;
        height:200px;
        margin-bottom: 20px;
    }
    .news-content .content-card-container .content-card .content-card-main>span{
       font-size: 14px;
        line-height: 1.6;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .news-container {
        height: 100%;
        padding-top: 30px;
        width:100%;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    .news-container .title {
        display: flex;
        justify-content: center;
    }

    .news-container .title > h1 {
        font-weight: 600;
        border-bottom: 4px solid #4969ff;
        padding-bottom: 4px;
    }
    #news-prev, #news-next {
        display: none;

    }

    .news-content {
        margin-top: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 50px;
        justify-content: start;
        position: relative;
    }


    .news-content .content-title {
        width:100%;
        height: 100%;
        display: flex;
        border-bottom: 1px solid #ccc;
    }
    .news-content .content-title > h1 {
        font-size: 22px;
        color: #6c6c6c;
        margin-left: 15px;
    }
    .news-content .content-card-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        padding: 20px 0;
        gap: 30px;
        scroll-behavior: smooth;
    }
    .news-content .content-card-container::-webkit-scrollbar {
        display: none;
    }
    .news-content .content-card-container .content-card {
        min-width: 380px;
        width:380px;
        height: 500px;
        border: 1px solid #e8e8e8;
        border-radius: 8px;

    }
    .news-content .content-card-container .content-card .content-card-header{
        min-width: 80px;
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;

    }
    .news-content .content-card-container .content-card .content-card-header .content-card-header-img{
        width:60px;
        height:60px;

    }
    .news-content .content-card-container .content-card .content-card-header .header-section{
        display: flex;
        flex-direction: column;
        text-align: end;
    }
    .news-content .content-card-container .content-card .content-card-header .header-section > h5{
        font-size: 18px;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    .news-content .content-card-container .content-card .content-card-header .header-section > span{
        font-size: 14px;
        font-weight: 300;
    }
    .news-content .content-card-container .content-card .content-card-main{
        width: 100%;
        height:420px;
        max-height: 420px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        position: relative;
    }

    .news-content .content-card-container .content-card .content-card-main .scroll-indicator{
        transition: all 0.5s ease-in-out;
        display: none;
    }
    .news-content .content-card-container .content-card .content-card-main::-webkit-scrollbar{
        display: none;
    }
    .news-content .content-card-container .content-card .content-card-main .content-card-main-img{
        width:200px;
        height:200px;
        margin-bottom: 20px;
    }
    .news-content .content-card-container .content-card .content-card-main>span{
        font-size: 14px;
        line-height: 1.6;
    }

}
@media (min-width: 0px) and (max-width: 767px) {
    .news-container {
        height: 100%;
        padding-top: 30px;
        width:100%;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    .news-container .title {
        display: flex;
        justify-content: center;
    }

    .news-container .title > h1 {
        font-weight: 600;
        border-bottom: 4px solid #4969ff;
        padding-bottom: 4px;
    }
    #news-prev, #news-next {
        display: none;

    }

    .news-content {
        margin-top: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        justify-content: start;
        position: relative;
    }


    .news-content .content-title {
        width:100%;
        height: 100%;
        display: flex;
        border-bottom: 1px solid #ccc;
    }
    .news-content .content-title > h1 {
        font-size: 16px;
        color: #6c6c6c;
    }
    .news-content .content-card-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        padding: 20px 10px;
        gap: 30px;
        scroll-behavior: smooth;
    }
    .news-content .content-card-container::-webkit-scrollbar {
        display: none;
    }
    .news-content .content-card-container .content-card {

        width:100%;
        height: 480px;
        border: 1px solid #e8e8e8;
        border-radius: 8px;

    }
    .news-content .content-card-container .content-card .content-card-header{
        min-width: 80px;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;

    }
    .news-content .content-card-container .content-card .content-card-header .content-card-header-img{
        width:40px;
        height:40px;

    }
    .news-content .content-card-container .content-card .content-card-header .header-section{
        display: flex;
        flex-direction: column;
        text-align: end;
    }
    .news-content .content-card-container .content-card .content-card-header .header-section > h5{
        font-size: 16px;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    .news-content .content-card-container .content-card .content-card-header .header-section > span{
        font-size: 14px;
        font-weight: 300;
    }
    .news-content .content-card-container .content-card .content-card-main{
        width: 100%;
        height:420px;
        max-height: 420px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        position: relative;
    }


    .news-content .content-card-container .content-card .content-card-main .scroll-indicator{
        transition: all 0.5s ease-in-out;
        display: none;
    }
    .news-content .content-card-container .content-card .content-card-main::-webkit-scrollbar{
        display: none;
    }
    .news-content .content-card-container .content-card .content-card-main .content-card-main-img{
        width:140px;
        height:140px;
        margin-bottom: 20px;
    }
    .news-content .content-card-container .content-card .content-card-main>span{
        font-size: 14px;
        line-height: 1.6;
    }
}
