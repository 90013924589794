@media (min-width: 1025px) {
    .way-container {
        width: 100%;
        height: 100%;
    }
    .way-container .way-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .way-container .way-title > h1 {
        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .way-container .way-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 50px;
    }
    .way-container .google-map {

        width: 100%;
        height: 500px;
    }
    .way-container .way-content .way-content-text {
        margin-top: 20px;
        width: 100%;

    }
    .way-container .way-content .way-content-text .title {
       display: flex;
        gap:20px
    }
    .way-container .way-content .way-content-text .title > span{
        text-transform: uppercase;
        font-size: 24px;
        margin: 0 0 0 10px;
        width: 50%;
        color:#252525;
    }
    .way-container .way-content .way-content-text .title > p{
        text-transform: uppercase;
        font-size: 24px;
        text-align: end;
        width: 50%;
        margin: 0 10px 0 0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .title > p:after{
       position: absolute;
        top: 18px;
        /*left:0;*/
        content:"";
        background-color: #4969ff;
        width:30px;
        height:3px;
        transform: rotate(-60deg);
    }
    .way-container .way-content .way-content-text .description {
        display: flex;
        gap:20px;
        margin-top: 10px;
    }
    .way-container .way-content .way-content-text .description > span{
        text-transform: uppercase;
        font-size: 24px;
        margin: 0 0 0 10px;
        text-align: end;
        width: 50%;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .description > p{
        text-transform: uppercase;
        font-size: 24px;
        margin: 0 0 0 10px;
        width: 50%;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .description > span:after{
        position: absolute;
        top: 18px;
        /*left:0;*/
        content:"";
        background-color: #4969ff;
        width:30px;
        height:3px;
        transform: rotate(-60deg);
    }
    .way-container .way-content .way-content-text .description .description-content{
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap:10px;
        padding-top:10px

    }
    .way-container .way-content .way-content-text .description .description-content >p{
       margin:0;
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1024px){
    .way-container {
        width: 100%;
        height: 100%;
    }
    .way-container .way-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .way-container .way-title > h1 {
        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .way-container .way-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 50px;
    }
    .way-container .google-map {

        width: 100%;
        height: 400px;
    }
    .way-container .way-content .way-content-text {
        margin-top: 20px;
        width: 100%;

    }
    .way-container .way-content .way-content-text .title {
        display: flex;
        gap:20px
    }
    .way-container .way-content .way-content-text .title > span{
        text-transform: uppercase;
        font-size: 18px;
        color:#252525;
        margin: 0 0 0 15px;
        align-self: center;
    }
    .way-container .way-content .way-content-text .title > p{
        text-transform: uppercase;
        font-size: 24px;
        margin:0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .title > p:after{
        content:" / ";
        color: #4969ff;
        font-weight: bold;
    }
    .way-container .way-content .way-content-text .description {
        display: flex;
        gap:20px;
        margin-top: 10px;
    }
    .way-container .way-content .way-content-text .description > span{
        text-transform: uppercase;
        font-size: 24px;
        margin:0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .description > p{
        text-transform: uppercase;
        font-size: 18px;
        margin: 0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .description > span:after{
        content:" / ";
        color: #4969ff;
        font-weight: bold;
    }
    .way-container .way-content .way-content-text .description .description-content{
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap:10px;
        padding-top:10px
    }
    .way-container .way-content .way-content-text .description .description-content >p{
        margin:0;
        font-size: 16px;
    }
}
@media (min-width: 0px) and (max-width: 767px){
    .way-container {
        width: 100%;
        height: 100%;
    }
    .way-container .way-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .way-container .way-title > h1 {
        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .way-container .way-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 10px;
    }
    .way-container .google-map {

        width: 100%;
        height: 400px;
    }
    .way-container .way-content .way-content-text {
        margin-top: 20px;
        width: 100%;

    }
    .way-container .way-content .way-content-text .title {
        display: flex;
        flex-direction: column;
        gap:20px
    }
    .way-container .way-content .way-content-text .title > span{
        text-transform: uppercase;
        font-size: 16px;
        margin:0;
        color:#252525;
    }
    .way-container .way-content .way-content-text .title > p{
        text-transform: uppercase;
        font-size: 20px;
        margin:0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .title > p:after{
        content:" / ";
        color: #4969ff;
        font-weight: bold;
    }
    .way-container .way-content .way-content-text .description {
        display: flex;
        flex-direction: column;
        gap:8px;
        margin-top: 30px;
    }
    .way-container .way-content .way-content-text .description > span{
        text-transform: uppercase;
        font-size: 20px;
        margin:0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .description > p{
        text-transform: uppercase;
        font-size: 16px;
        margin: 0;
        color:#252525;
        position: relative;
    }
    .way-container .way-content .way-content-text .description > span:after{
        /*position: relative;*/
        /*top: 40px;*/
        content:" / ";
        color: #4969ff;
        font-weight: bold;
        transform: rotate(-60deg);
    }
    .way-container .way-content .way-content-text .description .description-content{
        /*margin-left: 20px;*/
        display: flex;
        flex-direction: column;
        gap:10px;
        padding-top:3px
    }
    .way-container .way-content .way-content-text .description .description-content >p{
        margin:0;
        font-size: 17px;
    }
}

