@media (min-width: 1025px)  {
    .crumbs-fs{
    color: #6c6c6c;
    font-size: 30px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .crumbs-fs{

        font-size: 20px;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .crumbs-fs{

        font-size: 17px;
    }
}
