@media (min-width: 1025px) {
    .organization_container {
        width: 100%;
        margin: 40px 0;
        box-sizing: border-box;
        padding: 0 150px;
    }

    .sub_content {
        width: 100%;
        background: #fff;
        box-sizing: border-box;
    }

    .sub_content .sub_title {
        /*margin-bottom: 40px;*/
        font-size: 40px;
        color: #333;
        width: 100%;
        text-align: center;
        white-space: normal;
        word-break: keep-all;
        word-wrap: break-word;
        transition: all 0.3s;
    }

    .sub_content .sub_title::after {
        content: '';
        display: block;
        width: 40px;
        height: 3px;
        margin: 10px auto 0;
        background: #4969ff;
        transition: all 0.2s;
    }

    .sub_content .sub_organization .sub_organization_con {
        width: 100%;
        margin-top: 40px;
        text-align: center;
    }

    .sub_content .sub_organization .sub_organization_con h4 {
        position: relative;
        display: inline-block;
        left:-1%;
        width: 200px;
        height: 200px;
        margin-bottom: 40px;
        box-sizing: border-box;
        border: 17px solid #4969ff;
        border-radius: 50%;
        font-size: 24px;
        font-weight: bold;
        color: #252525;
        line-height: 166px;
    }

    .sub_content .sub_organization .sub_organization_con h4::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -17px;
        transform: translateX(-50%);
        display: block;
        width: 17px;
        height: 17px;
        background: #fff;
        border-radius: 50%;
    }

    .sub_organization .sub_organi_box {
        display: flex;
justify-content: center;
        position: relative;
        width: 100%;
    }

    .sub_organization .sub_organi_box::before {
        content: '';
        position: absolute;
        left: 49%;
        top: 0;
        transform: translateX(-50%);
        display: block;
        width: 77.7%;
        height: 1px;
        background: #b0b0b0;
    }

    .sub_organization .sub_organi_box dl {
        position: relative;
        float: left;
        width: 17.34%;
        margin-right: 2.07%;
        box-sizing: border-box;
        padding-top: 60px;
    }

    .sub_organization .sub_organi_box dl::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        display: block;
        width: 1px;
        height: 98%;
        background: #b0b0b0;
    }
    .sub_organization .sub_organi_box dl:nth-of-type(1)::before {
        height: 80px;
    }
    .sub_organization .sub_organi_box dl:nth-of-type(4)::before {
        height: 350px;
    }
    .sub_organization .sub_organi_box dl:nth-of-type(5)::before {
        height: 350px;
    }



    .sub_organization .sub_organi_box dl:nth-of-type(3)::before {
        top: -40px;
        height: 380px;
    }


    .sub_organization .sub_organi_box dt {
        position: relative;
        width: 100%;
        height: 80px;
        background: #5e79f5;
        border-radius: 40px;
        font-size: 28px;
        font-weight: bold;
        color: #fff;
        line-height: 80px;
        z-index: 1;
    }

    .sub_organization .sub_organi_box dt:before {
        content: '';
        position: absolute;
        left: 50%;
        top: -3px;
        transform: translateX(-50%);
        display: block;
        width: 8px;
        height: 8px;
        background: #fff;
        border: 1px solid #252525;
        border-radius: 50%;
    }

    .sub_organization .sub_organi_box dd {
        position: relative;
        width: 100%;
        height: 60px;
        margin-top: 20px;
        background: #c8dffd;
        box-sizing: border-box;
        /*border: 1px solid #ddd;*/
        border-radius: 16px;
        font-size: 20px;
        color: #252525;
        line-height: 58px;
        z-index: 1;
    }

    .sub_organization .sub_organi_box dl::after {
        content: '';
        display: block;
        clear: both;
    }

    .sub_organization .sub_organi_box::after {
        content: '';
        display: block;
        clear: both;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .organization_container {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 30px;
    }

    .sub_content {
        width: 100%;
        background: #fff;
        box-sizing: border-box;
    }

    .sub_content .sub_title {
        /*margin-bottom: 40px;*/
        font-size: 30px;
        color: #333;
        width: 100%;
        text-align: center;
        white-space: normal;
        word-break: keep-all;
        word-wrap: break-word;
        transition: all 0.3s;
    }

    .sub_content .sub_title::after {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        margin: 10px auto 0;
        background: #4969ff;
        transition: all 0.2s;
    }

    .sub_content .sub_organization .sub_organization_con {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 100px;
        text-align: center;
    }

    .sub_content .sub_organization .sub_organization_con h4 {
        position: relative;
        display: inline-block;
        left:-1%;
        width: 160px;
        height: 160px;
        margin-bottom: 40px;
        box-sizing: border-box;
        border: 14px solid #4969ff;
        border-radius: 50%;
        font-size: 24px;
        font-weight: bold;
        color: #252525;
        line-height: 140px;
    }

    .sub_content .sub_organization .sub_organization_con h4::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -17px;
        transform: translateX(-50%);
        display: block;
        width: 17px;
        height: 17px;
        background: #fff;
        border-radius: 50%;
    }

    .sub_organization .sub_organi_box {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    .sub_organization .sub_organi_box::before {
        content: '';
        position: absolute;
        left: 49%;
        top: 0;
        transform: translateX(-50%);
        display: block;
        width: 77.7%;
        height: 1px;
        background: #b0b0b0;
    }

    .sub_organization .sub_organi_box dl {
        position: relative;
        float: left;
        width: 17.34%;
        margin-right: 2.07%;
        box-sizing: border-box;
        padding-top: 60px;
    }

    .sub_organization .sub_organi_box dl::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        display: block;
        width: 1px;
        height: 98%;
        background: #b0b0b0;
    }
    .sub_organization .sub_organi_box dl:nth-of-type(1)::before {
        height: 80px;
    }
    .sub_organization .sub_organi_box dl:nth-of-type(4)::before {
        height: 350px;
    }
    .sub_organization .sub_organi_box dl:nth-of-type(5)::before {
        height: 350px;
    }



    .sub_organization .sub_organi_box dl:nth-of-type(3)::before {
        top: -40px;
        height: 380px;
    }


    .sub_organization .sub_organi_box dt {
        position: relative;
        width: 100%;
        height: 70px;
        background: #5e79f5;
        border-radius: 40px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        line-height: 70px;
        z-index: 1;
    }

    .sub_organization .sub_organi_box dt:before {
        content: '';
        position: absolute;
        left: 50%;
        top: -3px;
        transform: translateX(-50%);
        display: block;
        width: 8px;
        height: 8px;
        background: #fff;
        border: 1px solid #252525;
        border-radius: 50%;
    }

    .sub_organization .sub_organi_box dd {
        position: relative;
        width: 100%;
        height: 60px;
        margin-top: 20px;
        background: #c8dffd;
        box-sizing: border-box;
        /*border: 1px solid #ddd;*/
        border-radius: 16px;
        font-size: 17px;
        color: #252525;
        line-height: 58px;
        z-index: 1;
    }

    .sub_organization .sub_organi_box dl::after {
        content: '';
        display: block;
        clear: both;
    }

    .sub_organization .sub_organi_box::after {
        content: '';
        display: block;
        clear: both;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .organization_container {
        width: 100%;
        margin: 0 auto;
        padding: 30px 0;
        box-sizing: border-box;
    }

    .sub_content {
        width: 100%;
        margin: 0 auto;
        background: #fff;
        box-sizing: border-box;
    }

    .sub_content .sub_title {
        margin-bottom: 40px;
        font-size: 40px;
        color: #333;
        text-align: center;
        white-space: normal;
        word-break: keep-all;
        word-wrap: break-word;
        transition: all 0.3s;
    }

    .sub_content .sub_title::after {
        content: '';
        display: block;
        width: 40px;
        height: 3px;
        margin: 10px auto 0;
        background: #4969ff;
        transition: all 0.2s;
    }

    .sub_content .sub_organization .sub_organization_con {
        width: 100%;
        margin-top: 40px;
        text-align: center;
    }

    .sub_content .sub_organization .sub_organization_con h4 {
        position: relative;
        display: inline-block;
        width: 200px;
        height: 200px;
        margin-bottom: 40px;
        box-sizing: border-box;
        border: 17px solid #4969ff;
        border-radius: 50%;
        font-size: 24px;
        font-weight: bold;
        color: #252525;
        line-height: 166px;
    }

    .sub_content .sub_organization .sub_organization_con h4::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -17px;
        transform: translateX(-50%);
        display: block;
        width: 17px;
        height: 17px;
        background: #fff;
        border-radius: 50%;
    }

    .sub_organization .sub_organi_box {
        position: relative;
        width: 100%;
        padding: 0px 20px;
    }

    .sub_organization .sub_organi_box::before {
        content: '';
        position: absolute;
        left: 47.5%;
        top: 0;
        transform: translateX(-50%);
        display: none;
        width: 77.7%;
        height: 1px;
        background: #b0b0b0;
    }

    .sub_organization .sub_organi_box dl {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .sub_organization .sub_organi_box dl::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        display: none;
        width: 1px;
        height: 98%;
        background: #b0b0b0;
    }


    /*.sub_organization .sub_organi_box dl:nth-of-type(1)::before {*/
    /*    top: -40px;*/
    /*    display: block;*/
    /*    height: 1200px;*/
    /*}*/

    .sub_organization .sub_organi_box dl:nth-child(1)::before {
        top: -40px;
        display: block;
        height: 100%;
    }
    .sub_organization .sub_organi_box dl:nth-child(2)::before,
    .sub_organization .sub_organi_box dl:nth-child(3)::before,
    .sub_organization .sub_organi_box dl:nth-child(4)::before,
    .sub_organization .sub_organi_box dl:nth-child(5)::before {
        top: 10px;
        display: block;
        height: 95%;
    }



    .sub_organization .sub_organi_box dt {
        position: relative;
        width: 100%;
        height: 60px;
        background: #5e79f5;
        border-radius: 40px;
        margin-bottom: 50px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        line-height: 60px;
        z-index: 1;
    }

    .sub_organization .sub_organi_box dt:before {
        display:none
    }

    .sub_organization .sub_organi_box dd {
        position: relative;
        width: 100%;
        height: 50px;
        margin-top: 20px;
        background: #c8dffd;
        box-sizing: border-box;
        /*border: 1px solid #ddd;*/
        border-radius: 16px;
        font-size: 18px;
        color: #252525;
        line-height: 50px;
        z-index: 1;
    }

    .sub_organization .sub_organi_box dl::after {
        content: '';
        display: block;
        clear: both;
    }

    .sub_organization .sub_organi_box::after {
        content: '';
        display: block;
        clear: both;
    }
}
