@media (min-width: 1025px) {
    .software-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .software-container .software-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .software-container .software-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .software-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 1600px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .software-content .software-product-list {
        margin-bottom: 60px;
        border: 1px solid #e8e8e8;
        padding : 10px 30px;
        height: 300px;
        border-radius: 14px;
        box-shadow: #c4c4c4 3px  0 20px;
    }
    .software-content .software-product-list:nth-child(1) .product-img {
       background-image: url("./../../assets/images/meterarium.jpg");
         background-size: contain;
       background-position: center;
       background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        /*padding-right: 30px;*/
        padding: 20px;
        position: relative;
        border-radius:  16px;
    }
    .software-content .software-product-list:nth-child(2) .product-img {
        background-image: url("./../../assets/images/deepLearning.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        padding: 20px;
        position: relative;
        border-radius:  16px;
    }
    .software-content .software-product-list:nth-child(3) .product-img {
        background-image: url("./../../assets/images/security.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        padding: 20px;
        position: relative;
        border-radius:  16px;
    }
    .software-content .software-product-list:nth-child(4) .product-img {
        background-image: url("./../../assets/images/wasap.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        padding: 20px;
        position: relative;
    }
    .software-content .software-product-list .product-description {
        width: 65%;
        float:right;
        padding: 20px 30px;
        height:260px;
        overflow-y: scroll;
        -ms-overflow-style: none;
    }
    .software-content .software-product-list .product-description::-webkit-scrollbar {
        display: none;
    }
    .software-content .software-product-list .product-description > h1 {
        font-size: 30px;
        color: #4969ff;
        margin-bottom: 30px;
    }
    .software-content .software-product-list .product-description > p {
        font-size: 18px;
        color: #6c6c6c;
    }

}
@media (min-width: 768px) and (max-width: 1024px) {
    .software-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .software-container .software-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .software-container .software-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .software-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;
        padding: 30px;

    }
    .software-content .software-product-list {
        margin-bottom: 40px;
        border: 1px solid #e8e8e8;
        padding : 10px 30px;
        height: 300px;
        border-radius: 14px;
        box-shadow: #c4c4c4 3px  0 20px;
    }
    .software-content .software-product-list:nth-child(1) .product-img {
        background-image: url("./../../assets/images/meterarium.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        /*padding-right: 30px;*/
        padding: 20px;
        position: relative;
        border-radius:  16px;
    }
    .software-content .software-product-list:nth-child(2) .product-img {
        background-image: url("./../../assets/images/deepLearning.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        padding: 20px;
        position: relative;
        border-radius:  16px;
    }
    .software-content .software-product-list:nth-child(3) .product-img {
        background-image: url("./../../assets/images/security.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        padding: 20px;
        position: relative;
        border-radius:  16px;
    }

    .software-content .software-product-list:nth-child(4) .product-img {
        background-image: url("./../../assets/images/wasap.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        float:left;
        width:35%;
        height: 100%;
        padding: 20px;
        position: relative;
    }
    .software-content .software-product-list .product-description {
        width: 65%;
        float:right;
        height: 100%;
        padding: 20px 30px;
        overflow-y: scroll;
        -ms-overflow-style: none;
    }
    .software-content .software-product-list .product-description::-webkit-scrollbar {
        display: none;
    }
    .software-content .software-product-list .product-description > h1 {
        font-size: 24px;
        color: #4969ff;
        margin-bottom: 30px;
    }
    .software-content .software-product-list .product-description > p {
        font-size: 16px;
        color: #6c6c6c;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .software-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .software-container .software-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .software-container .software-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .software-content {
        display: flex;
        flex-direction: column;
        padding:15px;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;

    }
    .software-content .software-product-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        border: 1px solid #e8e8e8;
        /*padding : 10px 30px;*/
        /*height: 300px;*/
        width:100%;
        border-radius: 14px;
        box-shadow: #c4c4c4 3px  0 20px;
    }
    .software-content .software-product-list:nth-child(1) .product-img {
        background-image: url("./../../assets/images/meterarium.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        width:100%;
        height: 180px;
        position: relative;
    }
    .software-content .software-product-list:nth-child(2) .product-img {
        background-image: url("./../../assets/images/deepLearning.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        width:100%;
        height: 200px;
        position: relative;
    }
    .software-content .software-product-list:nth-child(3) .product-img {
        background-image: url("./../../assets/images/security.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        width:100%;
        height: 200px;

        position: relative;
    }
    .software-content .software-product-list:nth-child(4) .product-img {
        background-image: url("./../../assets/images/wasap.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        width:100%;
        height: 200px;
        position: relative;
    }
    .software-content .software-product-list .product-description {
        width: 100%;
        float:right;
        height: 100%;
        padding: 20px 20px;
        overflow-y: scroll;
    }
    .software-content .software-product-list .product-description > h1 {
        font-size: 20px;
        color: #4969ff;
        margin-bottom: 30px;
    }
    .software-content .software-product-list .product-description > p {
        font-size: 14px;
        color: #6c6c6c;
    }
}
