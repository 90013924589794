@media (min-width: 1400px) {
    .product-container {
        height: 830px;
        padding-top: 30px;
    }

    .product-container .title {
        display: flex;
        justify-content: center;
    }

    .product-container .title > h1 {
        font-weight: 600;
        border-bottom: 4px solid #4969ff;
        padding-bottom: 4px;
    }

    .product-content {
        margin-top: 50px;
        height: 600px;
        display: flex;
        justify-content: center;
    }

    .product-content .product-item {
        height: 600px;
        width: 1200px;
    }

    .product-content .product-item .product-item-left {
        float: left;
        width: 50%;
        height: 600px;
        position: relative;
        background-image: url('./../../assets/images/software.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-item-left .product-item-text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }

    .product-item-left .product-item-text > h1 {
        font-size: 30px;
        color: #fff;
    }

    .product-item-left:hover .product-item-text {
        display: none;
    }

    .product-content .product-item .product-item-left:hover .product-item-left-detail {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }

    .product-content .product-item .product-item-left .product-item-left-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product-content .product-item .product-item-left .product-item-left-detail {
        display: none;
        position: relative;
    }

    .product-content .product-item .product-item-left .product-item-left-detail .content {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product-content .product-item .product-item-left .product-item-left-detail .content > h1 {
        font-size: 30px;
    }

    .product-content .product-item .product-item-left .product-item-left-detail .content > a {
        border: 2px solid #fff;
        color: #fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 15px;
        text-decoration: none;
    }

    .product-content .product-item .product-item-left .product-item-left-detail .content > a:hover {
        border: 1px solid #fff;
        color: #252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-item-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 600px;
        height: 600px;
    }

    .product-image-container {
        width: 300px;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

    }

    .product-image-1 {
        background-image: url('./../../assets/images/device.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;

    }

    .product-image-1 .product-image-text {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-1 .product-image-text > h1 {
        font-size: 30px;
        color: #fff

    }

    .product-image-1:hover .product-image-text {
        display: none;
    }

    .product-image-1-detail {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }

    .product-image-1:hover .product-image-1-detail {
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    /*.product-image-1:hover .product-image-1-detail .content{*/
    /*    display: block;*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/
    .product-image-1 .product-image-1-detail .content > h1 {
        font-size: 30px;
    }

    .product-image-1 .product-image-1-detail .content > a {
        border: 2px solid #fff;
        color: #fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 15px;
        text-decoration: none;
    }

    .product-image-1 .product-image-1-detail .content > a:hover {
        border: 1px solid #fff;
        color: #252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-image-1:hover .product-image-1-detail .content {
        text-align: center;
    }

    .product-image-2 {
        background-image: url('./../../assets/images/secure.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-2 .product-image-text {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-2 .product-image-text > h1 {
        font-size: 30px;
        color: #fff

    }

    .product-image-2:hover .product-image-text {
        display: none;
    }

    .product-image-2-detail {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }

    .product-image-2:hover .product-image-2-detail {
        display: grid;
        justify-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
    }

    .product-image-2 .product-image-2-detail .content > h1 {
        font-size: 30px;
    }

    .product-image-2 .product-image-2-detail .content > a {
        border: 2px solid #fff;
        color: #fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 15px;
        text-decoration: none;
    }

    .product-image-2 .product-image-2-detail .content > a:hover {
        border: 1px solid #fff;
        color: #252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-image-2:hover .product-image-2-detail .content {
        text-align: center;
    }

    .product-image-3 {
        background-image: url('./../../assets/images/ai.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-3 .product-image-text {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-3 .product-image-text > h1 {
        font-size: 30px;
        color: #fff

    }

    .product-image-3:hover .product-image-text {
        display: none;
    }

    .product-image-3-detail {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }

    .product-image-3:hover .product-image-3-detail {
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .product-image-3 .product-image-3-detail .content > h1 {
        font-size: 30px;
    }

    .product-image-3 .product-image-3-detail .content > a {
        border: 2px solid #fff;
        color: #fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 15px;
        text-decoration: none;
    }

    .product-image-3 .product-image-3-detail .content > a:hover {
        border: 1px solid #fff;
        color: #252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-image-3:hover .product-image-3-detail .content {
        text-align: center;
    }

    .product-image-4 {
        background-image: url('./../../assets/images/service.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-4 .product-image-text {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .product-image-4 .product-image-text > h1 {
        font-size: 30px;
        color: #fff

    }

    .product-image-4:hover .product-image-text {
        display: none;
    }

    .product-image-4-detail {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }

    .product-image-4:hover .product-image-4-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .product-image-4 .product-image-4-detail .content > h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .product-image-4 .product-image-4-detail .content > a {
        border: 2px solid #fff;
        color: #fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 15px;
        text-decoration: none;
    }

    .product-image-4 .product-image-4-detail .content > a:hover {
        border: 1px solid #fff;
        color: #252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-image-4:hover .product-image-4-detail .content {
        text-align: center;
    }
}
@media (min-width: 768px) and (max-width: 1399px) {
    .product-container {
        height: 100%;
        padding-top: 15px;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/

    }
    .product-container .title {
        display: flex;
        justify-content: center;
    }
    .product-container .title > h1 {
        font-weight: 600;
        border-bottom: 4px solid #4969ff;
        padding-bottom: 4px;
    }
    .product-content{
        margin-top: 50px;
        height: 100%;
        width:100%;
        /*padding-left: 100px;*/
        /*padding-right: 100px;*/
        display: flex;
        justify-content: start;
    }
    .product-content .product-item{
        height: 400px;
        width:100%;
    }
    .product-content .product-item .product-item-left{
        float: left;
        width:50%;
        height: 400px;
        position: relative;
        background-image:  url('./../../assets/images/software.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-item-left .product-item-text{
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        background-color:  rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    .product-item-left .product-item-text > h1{
        font-size: 30px;
        color:#fff;
    }
    .product-item-left:hover .product-item-text{
        display: none;
    }
    .product-content .product-item .product-item-left:hover .product-item-left-detail{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s ;
        color:#fff;
    }
    .product-content .product-item .product-item-left .product-item-left-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .product-content .product-item .product-item-left .product-item-left-detail{
        display: none;
        position: relative;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content{
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content > h1{
        font-size: 30px;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content > button{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content > button:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-item-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:50%;
        height: 400px;
    }

    .product-image-container {
        width: 50%;
        height: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

    }
    .product-image-1 {
        background-image: url('./../../assets/images/device.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;

    }
    .product-image-1 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-1 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-1:hover .product-image-text{
        display: none;
    }
    .product-image-1-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-1:hover .product-image-1-detail{
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-1 .product-image-1-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-1 .product-image-1-detail  .content  > button{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
    }
    .product-image-1 .product-image-1-detail  .content  > button:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-1:hover .product-image-1-detail .content{
        text-align: center;
    }

    .product-image-2 {
        background-image: url('./../../assets/images/secure.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-2 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-2 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-2:hover .product-image-text{
        display: none;
    }
    .product-image-2-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-2:hover .product-image-2-detail{
        display: grid;
        justify-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-2 .product-image-2-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-2 .product-image-2-detail  .content  > button{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
    }
    .product-image-2 .product-image-2-detail  .content  > button:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-2:hover .product-image-2-detail .content{
        text-align: center;
    }
    .product-image-3 {
        background-image:url('./../../assets/images/ai.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-3 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-3 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-3:hover .product-image-text{
        display: none;
    }
    .product-image-3-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-3:hover .product-image-3-detail{
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-3 .product-image-3-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-3 .product-image-3-detail  .content  > button{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
    }
    .product-image-3 .product-image-3-detail  .content  > button:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-3:hover .product-image-3-detail .content{
        text-align: center;
    }
    .product-image-4 {
        background-image:url('./../../assets/images/service.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-4 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-4 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-4:hover .product-image-text{
        display: none;
    }
    .product-image-4-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-4:hover .product-image-4-detail{
        display: grid;
        justify-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-4 .product-image-4-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-4 .product-image-4-detail  .content  > button{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
    }
    .product-image-4 .product-image-4-detail  .content  > button:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-4:hover .product-image-4-detail .content{
        text-align: center;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .product-container {
        height: 100%;
        padding-top: 15px;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/

    }
    .product-container .title {
        display: flex;
        justify-content: center;
    }
    .product-container .title > h1 {
        font-weight: 600;
        border-bottom: 4px solid #4969ff;
        padding-bottom: 4px;
    }
    .product-content{
        margin-top: 50px;
        height:600px;
        width:100%;
        /*padding-left: 100px;*/
        /*padding-right: 100px;*/
        display: flex;
        justify-content: start;
    }
    .product-content .product-item{
        height: 100%;
        width:100%;
    }
    .product-content .product-item .product-item-left{
        /*float: left;*/
        width:100%;
        height: 200px;
        position: relative;
        background-image:  url('./../../assets/images/software.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-item-left .product-item-text{
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        background-color:  rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    .product-item-left .product-item-text > h1{
        font-size: 30px;
        color:#fff;
    }
    .product-item-left:hover .product-item-text{
        display: none;
    }
    .product-content .product-item .product-item-left:hover .product-item-left-detail{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s ;
        color:#fff;
    }
    .product-content .product-item .product-item-left .product-item-left-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .product-content .product-item .product-item-left .product-item-left-detail{
        display: none;
        position: relative;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content{
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content > h1{
        font-size: 30px;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content > a{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
        text-decoration: none;
    }
    .product-content .product-item .product-item-left .product-item-left-detail .content > a:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }

    .product-item-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;
        height: 200px;
    }

    .product-image-container {
        width: 50%;
        height: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

    }
    .product-image-1 {
        background-image: url('./../../assets/images/device.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;

    }
    .product-image-1 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-1 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-1:hover .product-image-text{
        display: none;
    }
    .product-image-1-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-1:hover .product-image-1-detail{
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-1 .product-image-1-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-1 .product-image-1-detail  .content  > a{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
        text-decoration: none;
    }
    .product-image-1 .product-image-1-detail  .content  > a:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-1:hover .product-image-1-detail .content{
        text-align: center;
    }

    .product-image-2 {
        background-image: url('./../../assets/images/secure.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-2 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-2 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-2:hover .product-image-text{
        display: none;
    }
    .product-image-2-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-2:hover .product-image-2-detail{
        display: grid;
        justify-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-2 .product-image-2-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-2 .product-image-2-detail  .content  > a{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
        text-decoration: none;
    }
    .product-image-2 .product-image-2-detail  .content  > a:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-2:hover .product-image-2-detail .content{
        text-align: center;
    }
    .product-image-3 {
        background-image:url('./../../assets/images/ai.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-3 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-3 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-3:hover .product-image-text{
        display: none;
    }
    .product-image-3-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-3:hover .product-image-3-detail{
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-3 .product-image-3-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-3 .product-image-3-detail  .content  > a{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        text-decoration: none;
        margin-top:15px;
    }
    .product-image-3 .product-image-3-detail  .content  > a:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-3:hover .product-image-3-detail .content{
        text-align: center;
    }
    .product-image-4 {
        background-image:url('./../../assets/images/service.jpg');
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-4 .product-image-text{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .product-image-4 .product-image-text >h1{
        font-size: 30px;
        color:#fff

    }
    .product-image-4:hover .product-image-text{
        display: none;
    }
    .product-image-4-detail{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(2, 50, 112, 0.75);
        transition: all 1s;
        color: #fff;
    }
    .product-image-4:hover .product-image-4-detail{
        display: grid;
        justify-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
    }
    .product-image-4 .product-image-4-detail  .content > h1{
        font-size: 30px;
    }
    .product-image-4 .product-image-4-detail  .content  > a{
        border: 2px solid #fff;
        color:#fff;
        background: none;
        text-align: center;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        margin-top:15px;
        text-decoration: none;
    }
    .product-image-4 .product-image-4-detail  .content  > a:hover{
        border: 1px solid #fff;
        color:#252525;
        background-color: #fff;
        text-align: center;
        padding: 5px 20px;
        transition: all 0.3s ease-in-out;
    }
    .product-image-4:hover .product-image-4-detail .content{
        text-align: center;
    }
}
