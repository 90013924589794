@media (min-width: 1025px){
    .overview-container {
        padding: 0 60px 30px;
    }
    .overview-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .overview-title > h1 {

        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .overview-container .overview-content {
        border-bottom: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: center;
        margin: 30px 80px 50px;
    }

    .overview-content > ul {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        width:1600px;
        height: 100%;
        list-style: none;
    }
    .overview-content > ul > li {
        width: 100%;
        height: 100%;
        /*border-right: 1px solid #e0e0e0;*/
        box-sizing: border-box;
    }
    .overview-content > ul > li:nth-child(1) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;

    }
    .overview-content > ul > li:nth-child(1) > div {
        display: grid;
        width: 50%;
        height: 300px;
        padding:10px;
        justify-content: center;
        position: relative;
    }
    .overview-content > ul > li:nth-child(1) > div >div {
        text-align: center;
        align-self: end;
        position: relative;
        bottom: 30px;
    }
    .overview-content > ul > li:nth-child(1) > div >div > h3 {
        font-size: 22px;
        color: #4969ff;
    }
    .overview-content > ul > li:nth-child(1) > div >div > span {
        font-size: 17px;
    }
    .overview-content > ul > li:nth-child(1) > div >div > .contact-box {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        text-align: start;
    }

    .overview-content > ul > li:nth-child(1) > div >div > div>span {
        font-size: 17px;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(1) {
        background-image: url("./../../assets/images/building.jpg");
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: center 45px;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(2) {
        background-image: url("./../../assets/images/ceo.jpg");
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: center 45px  ;
        border-bottom: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(3) {
        background-image: url("./../../assets/images/location.jpg");
        background-repeat: no-repeat;
        background-size: 90px 90px;
        background-position: center 45px  ;
        border-right: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(4) {
        background-image: url("./../../assets/images/contact.jpg");
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position: center 45px  ;
    }
    .overview-content > ul > li:last-child {
        border:0;
        height: 100%;
        align-self: center;
        text-align: center;
        width:100%
    }
    .overview-content > ul > li:last-child > video {
        width:100%;
        height: 600px;
    }
    .overview-content-text {
        height: 100%;
        background-color: #5484de;
        padding: 15px 450px;
    }
    .overview-content-text  > h1{
        color:#fff;
        margin-bottom: 30px;
    }
    .overview-content-text  > span{
        font-size: 18px;
        color:#fff
    }
}
@media (min-width: 768px) and (max-width: 1024px){
    .overview-container {
    }
    .overview-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .overview-title > h1 {

        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .overview-container .overview-content {
        border-bottom: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 50px;
    }

    .overview-content > ul {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        width:100%;
        height: 100%;
        list-style: none;
    }
    .overview-content > ul > li {
        width: 100%;
        height: 100%;
        /*border-right: 1px solid #e0e0e0;*/
        box-sizing: border-box;
    }
    .overview-content > ul > li:nth-child(1) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;

    }
    .overview-content > ul > li:nth-child(1) > div {
        display: grid;
        width: 50%;
        height: 200px;
        padding:1px;
        justify-content: center;
        position: relative;
    }
    .overview-content > ul > li:nth-child(1) > div >div {
        text-align: center;
        align-self: end;
        position: relative;
    }
    .overview-content > ul > li:nth-child(1) > div >div > h3 {
        font-size: 22px;
        color: #4969ff;
        margin:0;
    }
    .overview-content > ul > li:nth-child(1) > div >div > span {
        font-size: 14px;
    }
    .overview-content > ul > li:nth-child(1) > div >div > .contact-box {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        text-align: start;
    }


    .overview-content > ul > li:nth-child(1) > div >div > div>span {
        font-size: 14px;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(1) {
        background-image: url("./../../assets/images/building.jpg");
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position: center 30px;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(2) {
        background-image: url("./../../assets/images/ceo.jpg");
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position: center 30px;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(3) {
        background-image: url("./../../assets/images/location.jpg");
        background-repeat: no-repeat;
        background-size: 70px 70px;
        background-position: center 20px;
        border-right: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(4) {
        background-image: url("./../../assets/images/contact.jpg");
        background-repeat: no-repeat;
        background-size: 60px 60px;
        background-position: center 30px;
        border-right: 1px solid #e0e0e0;
    }
    .overview-content > ul > li:last-child {
        border:0;
        height: 100%;
        align-self: center;
        text-align: center;
        width:100%
    }
    .overview-content > ul > li:last-child > video {
        width:100%;
        height: 400px;
    }
    .overview-content-text {
        height: 100%;
        background-color: #5484de;
        padding: 15px 15px;
    }
    .overview-content-text  > h1{
        color:#fff;
        font-size: 25px;
        margin-bottom: 30px;
    }
    .overview-content-text  > span{
        font-size: 16px;
        color:#fff
    }
    .overview-container .overview-content:nth-child(2) {
        width: 490px
    }
}

@media (min-width: 0px) and (max-width: 767px){
    .overview-container {
    }
    .overview-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .overview-title > h1 {

        text-transform: uppercase;
        padding-bottom:  4px;
        border-bottom: 4px solid #4969ff;
    }
    .overview-container .overview-content {
        border-bottom: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 50px;
    }

    .overview-content > ul {
        display: grid;
        padding: 0;
        margin: 0;
        height: 100%;
        list-style: none;
    }
    .overview-content > ul > li {
        width: 100%;
        height: 100%;
        /*border-right: 1px solid #e0e0e0;*/
        box-sizing: border-box;
    }
    .overview-content > ul > li:nth-child(1) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;

    }
    .overview-content > ul > li:nth-child(1) > div {
        display: grid;
        width: 50%;
        height: 220px;
        padding:1px;
        justify-content: center;
        position: relative;
    }
    .overview-content > ul > li:nth-child(1) > div >div {
        text-align: center;
        align-self: end;
        position: relative;
        bottom: 5px;
    }
    .overview-content > ul > li:nth-child(1) > div >div > h3 {
        font-size: 18px;
        color: #4969ff;
        margin:0
    }
    .overview-content > ul > li:nth-child(1) > div >div > span {
        font-size: 14px;
    }
    .overview-content > ul > li:nth-child(1) > div >div > .contact-box {
        display: flex;
        width:100%;
        flex-direction: column;
        margin-top: 2px;
        font-size: 13px;
        text-align: start;
    }

    .overview-content > ul > li:nth-child(1) > div:nth-child(1) {
        background-image: url("./../../assets/images/building.jpg");
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: center 25px;
        border-right: 1px solid #e8e8e8;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(2) {
        background-image: url("./../../assets/images/ceo.jpg");
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: center 25px  ;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(3) {
        background-image: url("./../../assets/images/location.jpg");
        background-repeat: no-repeat;
        background-size: 90px 90px;
        background-position: center 25px  ;
        border-right: 1px solid #e8e8e8;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
    }
    .overview-content > ul > li:nth-child(1) > div:nth-child(4) {
        background-image: url("./../../assets/images/contact.jpg");
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position: center 25px  ;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
    }
    .overview-content > ul > li:last-child {
        border:0;
        height: 300px;
        align-self: center;
        text-align: center;
        width:100%
    }
    .overview-content > ul > li:last-child > video {
        width:100%;
        height: 300px;
    }
    .overview-content-text {
        height: 100%;
        background-color: #5484de;
        padding: 15px 10px;
    }
    .overview-content-text  > h1{
        color:#fff;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .overview-content-text  > span{
        font-size: 12px;
        color:#fff
    }
}



