@media (min-width: 1025px) {

    .err-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .err-screen .err-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .err-screen .err-container > img {
        width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {

    .err-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    .err-screen .err-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .err-screen .err-container > img {
        width: 100%;
    }
}
@media (min-width: 0px) and (max-width: 767px) {

    .err-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    .err-screen .err-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .err-screen .err-container > img {
        width: 100%;
    }
}
