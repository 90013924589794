@media (min-width: 1025px) {
    .business-container {

    }

    .reference-header-image {
        background-image: url("./../../assets/images/reference.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 30%;
        height: 500px;
        opacity: 0.8;
        position: relative;
    }

    .reference-header-image .reference-header-image-navi {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        color: #252525;
        padding-left: 50px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ccc;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .reference-container {

    }

    .reference-header-image {
        background-image: url("./../../assets/images/reference.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 50%;
        height: 400px;
        opacity: 0.6;
        position: relative;
    }

    .reference-header-image .reference-header-image-navi {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        color: #252525;
        padding-left: 20px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ccc;
    }
}
@media (min-width: 0px) and (max-width: 767px) {
    .reference-container {

    }

    .reference-header-image {
        background-image: url("./../../assets/images/reference.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100%;
        height: 300px;
        opacity: 0.6;
        position: relative;
    }

    .reference-header-image .reference-header-image-navi {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        color: #252525;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ccc;
    }
}
