@media (min-width: 1025px) {


    .recruit-container .recruit-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .recruit-container .recruit-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }
    .recruit-container .recruit-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .recruit-container .recruit-content .search-section{
        width: 100%;
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
    }
    .recruit-container .recruit-content .search-section > input, button, select{
        height: 40px;
        border: 1px solid #e0dede;
    }
    .recruit-container .recruit-content .search-section > select{
        margin-right: 10px;
        width: 100px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #e0dede;
    }
    .recruit-container .recruit-content .search-section > button{
        position: relative;
        right: 0;
        margin-left: -30px;
        padding: 0;
        border:0 none;
        background:none;
    }
    .recruit-container .recruit-content .search-section > input{
        font-size: 14px;
        width: 300px;
        padding: 5px 10px;
    }
    .recruit-container .recruit-content .table-section > span {
        width:100%;
        display: flex;
        justify-content: end;
        font-size: 14px;
        color: #b4b4b4;
    }
    .recruit-container .recruit-content .table-section > table{
        width : 1200px;
        font-size: 14px;
        border-top: 3px solid #252525;
        margin-bottom: 100px;
        min-height: 300px;
    }
    .recruit-container .recruit-content .table-section > table > thead{
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr> th{
        border-bottom:1px solid #e0dede;
        border-right:1px solid #e0dede;
        height:40px;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr> th:nth-child(5){
        border-bottom:1px solid #e0dede;
        border-right: none;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(1){
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(3){
        width: 160px;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(4){
        width: 160px;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(5){
        width: 160px;
    }
    .recruit-container .recruit-content .table-section > table > tbody{
        height: 100%;
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr{
        height: 80px;
        min-height: 80px;
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr > td:nth-child(2){
        text-align: start;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr:hover{
        background-color: #4969ff;
        color: white;
        transition: all 0.5s;
        cursor: pointer;
    }

}
@media (min-width: 768px) and (max-width: 1024px) {



    .recruit-container .recruit-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .recruit-container .recruit-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }
    .recruit-container .recruit-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .recruit-container .recruit-content .search-section{
        width: 100%;
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
    }
    .recruit-container .recruit-content .search-section > input, button, select{
        height: 40px;
        border: 1px solid #e0dede;
    }
    .recruit-container .recruit-content .search-section > select{
        margin-right: 10px;
        width: 100px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #e0dede;
    }
    .recruit-container .recruit-content .search-section > button{
        position: relative;
        right: 0;
        margin-left: -30px;
        padding: 0;
        border:0 none;
        background:none;
    }
    .recruit-container .recruit-content .search-section > input{
        font-size: 14px;
        width: 300px;
        padding: 5px 10px;
    }
    .recruit-container .recruit-content .table-section > span {
        width:100%;
        display: flex;
        justify-content: end;
        font-size: 14px;
        color: #b4b4b4;
    }
    .recruit-container .recruit-content .table-section > table{
        width : 100%;
        font-size: 14px;
        border-top: 3px solid #252525;
        margin-bottom: 100px;
        min-height: 300px;
    }
    .recruit-container .recruit-content .table-section > table > thead{
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr> th{
        border-bottom:1px solid #e0dede;
        border-right:1px solid #e0dede;
        height:40px;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(1){
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(2){
        width: 33%;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(3){
        width: 120px;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(4){
        width: 160px;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(5){
        width: 160px;
        border-bottom:1px solid #e0dede;
        border-right: none;
    }
    .recruit-container .recruit-content .table-section > table > tbody{
        height: 100%;
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr{
        height: 80px;
        min-height: 80px;
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr > td:nth-child(2){
        text-align: start;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr:active{
        background-color: #4969ff;
        color: white;
        transition: all 0.5s;
        cursor: pointer;
    }


}
@media (min-width: 0px) and (max-width: 767px) {
    .recruit-container .recruit-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .recruit-container .recruit-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }
    .recruit-container .recruit-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .recruit-container .recruit-content .search-section{
        width: 100%;
        display: flex;
        height: 120px;
        justify-content: center;
        align-items: center;
    }
    .recruit-container .recruit-content .search-section > input, button, select{
        height: 40px;
        border: 1px solid #e0dede;
    }
    .recruit-container .recruit-content .search-section > select{
        margin-right: 10px;
        width: 120px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #e0dede;
    }
    .recruit-container .recruit-content .search-section > button{
        position: relative;
        right: 0;
        margin-left: -30px;
        padding: 0;
        border:0 none;
        width: 20px;
        height: 20px;
        background: #fff;
    }
    .recruit-container .recruit-content .search-section > input{
        font-size: 14px;
        width: 200px;
        padding: 5px 10px;
    }
    .recruit-container .recruit-content .table-section > span {
        width:100%;
        display: flex;
        justify-content: end;
        font-size: 14px;
        color: #b4b4b4;
        margin-bottom: 3px;
    }
    .recruit-container .recruit-content .table-section{
        padding: 0 10px;
    }
    .recruit-container .recruit-content .table-section > table{
        width : 100%;
        font-size: 14px;
        margin-bottom: 50px;
        min-height: 300px;
    }
    .recruit-container .recruit-content .table-section > table > thead{
        border-top: 3px solid #4969ff;
        border-bottom: 3px solid #4969ff;

        text-align: center;

    }
    .recruit-container .recruit-content .table-section > table > thead > tr> th{
       padding: 10px 0;
    }
    .recruit-container .recruit-content .table-section > table > thead > tr > th:nth-child(3) ,th:nth-child(4),th:nth-child(5){
        display: none;
    }
    .recruit-container .recruit-content .table-section > table > tbody{
        height: 100%;
        text-align: center;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr{
        height: 80px;
        min-height: 80px;
        text-align: center;
        border-bottom: 1px solid #ccc;
    }
    /*.recruit-container .recruit-content .table-section > table > tbody > tr:nth-child(1){*/

    /*    border-top: 1px solid #4969ff;*/
    /*}*/
    .recruit-container .recruit-content .table-section > table > tbody > tr > td:nth-child(1) {
        width:100px;
        /*border-right: 1px solid #ccc;*/

    }
    .recruit-container .recruit-content .table-section > table > tbody > tr > td:nth-child(2) {
        padding-left: 10px;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr > td:nth-child(3) ,td:nth-child(4),td:nth-child(5){
        display: none;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr > td:nth-child(2){
        text-align: start;
    }
    .recruit-container .recruit-content .table-section > table > tbody > tr:active{
        background-color: #4969ff;
        color: white;
        transition: all 0.5s;
        cursor: pointer;
    }


}
