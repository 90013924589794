@media (min-width: 1025px) {
    .brochure-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .brochure-container .brochure-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .brochure-container .brochure-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .brochure-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .brochure-content .pdf-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        gap:250px;
        align-items:center;
    }
    .brochure-content .pdf-btn > p{
        margin:0;
    }
    .brochure-content .pdf-btn >button {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #4969ff;
        color: white;
        border: 1px solid #4969ff;
    }
    .brochure-content .pdf-btn >button:hover {
        border: 1px solid #4969ff;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #fff;
        color: #4969ff;
    }
    .brochure-content .pdf-btn >button:disabled {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #ccc;
        color: white;
        border: 1px solid #ccc;
    }
    .pdf-page{
        width:1025px;
        height:768.75px;
    }
    .pdf-page > .pdf-page__canvas {
        width:1025px !important;
        height:768.75px !important;
    }


}
@media (min-width: 768px) and (max-width: 1024px) {
    .brochure-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .brochure-container .brochure-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .brochure-container .brochure-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .brochure-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;
        padding: 30px;
    }
    .brochure-content .pdf-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        align-items:center;
    }
    .brochure-content .pdf-btn > p{
        margin:0;
        width:100%
    }
    .brochure-content .pdf-btn >button {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #4969ff;
        color: white;
        border: 1px solid #4969ff;
        width:150px;
    }
    .brochure-content .pdf-btn >button:active {
        border: 1px solid #4969ff;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #fff;
        color: #4969ff;
    }
    .brochure-content .pdf-btn >button:disabled {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #ccc;
        color: white;
        border: 1px solid #ccc;
    }

}
@media (min-width: 0px) and (max-width: 767px) {
    .brochure-container {
        width: 100%;
        height: 100%;
        display: grid;
    }

    .brochure-container .brochure-title {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .brochure-container .brochure-title > h1 {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 4px solid #4969ff;
    }

    .brochure-content {
        display: flex;
        flex-direction: column;
        padding:15px;
        justify-content: center;
        place-self: center;
        margin-bottom: 30px;
        width: 100%;

    }
    .brochure-content .pdf-btn {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        align-items:center;
    }
    .brochure-content .pdf-btn > p{
        margin:0;

    }
    .brochure-content .pdf-btn >button {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #4969ff;
        color: white;
        border: 1px solid #4969ff;
    }
    .brochure-content .pdf-btn >button:disabled {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #ccc;
        color: white;
        border: 1px solid #ccc;
    }
    .brochure-content .pdf-btn >button:active {
        border: 1px solid #4969ff;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #fff;
        color: #4969ff;
    }
    .pdf-page{
        width:100%;
        height:100%;
    }
    .pdf-page > .pdf-page__canvas {
        width:100% !important;
        height:100% !important;
    }


}
